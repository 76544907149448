import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import webRoutes from "./app/routes/web";
import WebLayout from './app/layout';
import { ToasterContainer } from './app/components/toaster';
import "@fontsource/nunito-sans";
import "@fontsource/poppins";
import "@fontsource/sora";
import Chat from './app/views/chat';

const theme = createTheme({
  typography: {
    fontFamily: "sora, Nunito Sans, Poppins, sans-serif",
    h1: {
      fontSize: "72px",
    },
    h2: {
      fontSize: "60px",
    },
    h3: {
      fontSize: "48px",
    },
    h4: {
      fontSize: "36px",
    },
    h5: {
      fontSize: "24px",
    },
    h6: {
      fontSize: "18px",
    },
    subtitle1: {
      fontSize: "16px",
    },
    subtitle2: {
      fontSize: "14px",
    },
    caption: {
      fontSize: "12px",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ToasterContainer />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<WebLayout />}>
            {webRoutes.map((item, i) => (
              <Route key={i} path={item.path} element={item.component} />
            ))}
          </Route>
          <Route>
            <Route path="/chat" element={<Chat />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
