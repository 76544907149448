import React from 'react';
import { Box, Container, List, ListItem, ListItemText, Typography } from '@mui/material';
import Images from '../../assets/images';
import Colors from '../../style';

function PrivacyPolicy() {
  return (
    <Box
      sx={{
        backgroundImage: `url(${Images.bannerBackground})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
      }}
    >
      <Container maxWidth={"xl"}>
        <Box
          sx={{
            color: Colors.white,
            py: 3
          }}
        >
          <Typography>
            Fanoos is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share your personal information when you use our mobile application ("App") and services.
          </Typography>
          <List component={"ol"}>
            <ListItem sx={{ flexDirection: "column", alignItems: "flex-start" }}>
              <ListItemText
                sx={{ ".MuiTypography-root": { fontSize: "24px" } }}
                primary={`Information We Collect`}
              />
              <List component={"ul"}>
                <ListItem>
                  <Box>
                    <Typography>
                      For Members:
                    </Typography>
                    <List component={"ul"}>
                      <ListItem>
                        <ListItemText
                          primary={`Personal Information: When you register as a member, we collect personal details including your name, phone number, email address, date of birth, gender, and social media links.
                    `}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={`Verification: We use your phone number for OTP verification to ensure account security.`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={`Photos: You may upload photos for your profile or event participation.`}
                        />
                      </ListItem>
                    </List>
                  </Box>
                </ListItem>
                <ListItem>
                  <Box>
                    <Typography>
                      For Businesses:
                    </Typography>
                    <List component={"ul"}>
                      <ListItem>
                        <ListItemText
                          primary={`Business Information: We collect business-related information such as your company name, brand details, email address, and social media links.`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={`Subscription: Businesses are required to upload a screenshot of a $20 subscription payment as part of the registration process.`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={`Location Information: If your business operates physically, we collect location details such as country, city, address, and zip code.`}
                        />
                      </ListItem>
                    </List>
                  </Box>
                </ListItem>
              </List>
            </ListItem>
            <ListItem sx={{ flexDirection: "column", alignItems: "flex-start" }}>
              <ListItemText
                sx={{ ".MuiTypography-root": { fontSize: "24px" } }}
                primary={`How We Use Your Information`}
              />
              <List component={"ul"}>
                <ListItem>
                  <ListItemText
                    primary={`To provide and maintain our services, including managing events, offers, and user connections.`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={`To verify your identity and ensure account security.`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={`To send notifications related to events, offers, and other updates.`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={`To improve our platform by analyzing user behavior and feedback.`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={`To process payments and manage business subscriptions.  `}
                  />
                </ListItem>
              </List>
            </ListItem>
            <ListItem sx={{ flexDirection: "column", alignItems: "flex-start" }}>
              <ListItemText
                sx={{ ".MuiTypography-root": { fontSize: "24px" } }}
                primary={`Sharing Your Information`}
              />
              <Typography>We do not share your personal information with third parties, except:</Typography>
              <List component={"ul"}>
                <ListItem>
                  <ListItemText
                    primary={`When required by law or to comply with legal obligations.`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={`To protect the security and integrity of our platform.`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={`With service providers that help us operate our platform (e.g., payment processors, cloud services).`}
                  />
                </ListItem>
              </List>
            </ListItem>
            <ListItem sx={{ flexDirection: "column", alignItems: "flex-start" }}>
              <ListItemText
                sx={{ ".MuiTypography-root": { fontSize: "24px" } }}
                primary={`Data Security`}
              />
              <List component={"ul"}>
                <ListItem>
                  <ListItemText
                    primary={`We implement industry-standard security measures to protect your personal information. However, no method of data transmission over the internet is 100% secure, and we cannot guarantee its absolute security.`}
                  />
                </ListItem>
              </List>
            </ListItem>
            <ListItem sx={{ flexDirection: "column", alignItems: "flex-start" }}>
              <ListItemText
                sx={{ ".MuiTypography-root": { fontSize: "24px" } }}
                primary={`Your Rights`}
              />
              <List component={"ul"}>
                <ListItem>
                  <ListItemText
                    primary={`You have the right to access, update, or delete your personal information at any time. If you wish to exercise these rights, please contact us at [fanoosofficial.info@gmail.com].`}
                  />
                </ListItem>
              </List>
            </ListItem>
            <ListItem sx={{ flexDirection: "column", alignItems: "flex-start" }}>
              <ListItemText
                sx={{ ".MuiTypography-root": { fontSize: "24px" } }}
                primary={`Retention of Data`}
              />
              <List component={"ul"}>
                <ListItem>
                  <ListItemText
                    primary={`We retain your personal information for as long as your account is active or as needed to provide you with our services. After account deletion, your data will be permanently removed from our systems, except for any data we are legally required to retain.`}
                  />
                </ListItem>
              </List>
            </ListItem>
            <ListItem sx={{ flexDirection: "column", alignItems: "flex-start" }}>
              <ListItemText
                sx={{ ".MuiTypography-root": { fontSize: "24px" } }}
                primary={`Changes to This Privacy Policy`}
              />
              <List component={"ul"}>
                <ListItem>
                  <ListItemText
                    primary={`We may update this policy periodically. We will notify you of any significant changes by updating the "Last Updated" date at the top of this page.`}
                  />
                </ListItem>
              </List>
            </ListItem>
            <ListItem sx={{ flexDirection: "column", alignItems: "flex-start" }}>
              <ListItemText
                sx={{ ".MuiTypography-root": { fontSize: "24px" } }}
                primary={`Contact Us`}
              />
              <Typography>If you have any questions or concerns about this Privacy Policy, please contact us at:</Typography>
              <List component={"ul"}>
                <ListItem>
                  <ListItemText
                    primary={`Email: [fanoosofficial.info@gmail.com]`}
                  />
                </ListItem>
              </List>
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`By using Fanoos, you agree to this Privacy Policy and consent to the collection and use of your personal information as described above.`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`This privacy policy provides transparency and covers the necessary legal aspects for the personal information you collect from both members and businesses. Let me know if you'd like to adjust anything or include additional clauses!`}
              />
            </ListItem>
          </List>
        </Box>
      </Container>
    </Box>
  )
}

export default PrivacyPolicy