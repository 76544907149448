import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Header from './header';
import Footer from './footer';
import { useLocation } from 'react-router-dom';

function WebLayout() {
  const { pathname } = useLocation();
  return (
    <Box>
      <Header />
      <Box
        component={"main"}
        sx={{
          height: (pathname == "/support" || pathname == "/chat") ? "76.8vh" : "100%",
        }}
      >
        <Outlet />
      </Box>
      <Footer />
    </Box>
  )
}

export default WebLayout