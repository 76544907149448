import React from 'react';
import { Box, Grid2, Typography, CardMedia, Container } from '@mui/material';
import Colors from '../../style';
import Images from '../../assets/images';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';

function Footer() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        background: Colors.dark1,
      }}
    >
      <Container maxWidth={"xl"}>
        <Box
          component={"footer"}
          sx={{
            px: 3,
            py: 2
          }}
        >
          <Grid2
            container
            alignItems={"center"}
            justifyContent={pathname == "/" ? "center" : "space-between"}
            rowGap={{ md: 0, sm: 2, xs: 2 }}
          >
            <Grid2 size={{ md: 12, sm: 12, xs: 12 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontFamily: "Poppins", color: Colors.white }}>
                  {`Fanoos ${moment().format("YYYY")} © All rights reserved`}
                </Typography>
                <Box component={"div"} onClick={() => navigate("/chat")}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      color: pathname == "/chat" ? Colors.primary : Colors.white,
                      cursor: "pointer",
                      ":hover": {
                        color: Colors.primary
                      }
                    }}
                  >
                    Chat
                  </Typography>
                </Box>
              </Box>
            </Grid2>
            {pathname != "/" && (
              <Grid2 size={{ md: 4, sm: 12, xs: 12 }}>
                <Grid2 container spacing={2} justifyContent={"flex-end"}>
                  <Grid2>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { md: "row", sm: "column", xs: "column" },
                        justifyContent: "flex-end",
                        gap: 2,
                      }}
                    >
                      <Box
                        component={"a"}
                        href="#"
                        target="_blank"
                      >
                        <CardMedia
                          component={"img"}
                          src={Images.appStoreBtn}
                          sx={{
                            width: "100%",
                            objectFit: "contain"
                          }}
                        />
                      </Box>
                      {/* <Box component={"a"}>
                      <Typography
                        sx={{
                          fontSize: { md: "16px", sm: "14px", xs: "14px" },
                          fontFamily: "Poppins",
                          color: Colors.white,
                          cursor: "pointer",
                          ":hover": {
                            color: Colors.primary
                          }
                        }}
                        onClick={() => navigate("/terms-and-conditions")}
                      >
                        Terms & Conditions
                      </Typography>
                    </Box> */}
                      <Box
                        component={"a"}
                        href="#"
                        target="_blank"
                      >
                        <CardMedia
                          component={"img"}
                          src={Images.playStoreBtn}
                          sx={{
                            width: "100%",
                            objectFit: "contain"
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid2>
                </Grid2>
              </Grid2>
            )}
          </Grid2>
        </Box>
      </Container>
    </Box>
  )
}

export default Footer