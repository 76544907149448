import React from 'react';
import { Box, Container } from '@mui/material';

function Chat() {
  return (
    <Box sx={{ width: "99.8%", height: "99.2vh", }}>
      <iframe src="https://tawk.to/chat/674d501f2480f5b4f5a691c2/1ie301vfb" title="chat" style={{ width: "100%", height: "100%" }}></iframe>
    </Box>
  )
}

export default Chat
